<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">

				<CCard>
    			<CCardBody>
						<TheTableHeader
							:header="'DATA KARTU AKSES'"
							:subHeader="'DAFTAR KARTU AKSES MILIK LINTASARTA .'"
							buttonShow
							:buttonText="'Add New'"
							:buttonUrl="'cards/form/0'"
						/>
					</CCardBody>
				</CCard>

				<CCard>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							tableFilter
							sorter
							itemsPerPageSelect
							:items="cards"
							:fields="fields"
							:items-per-page="perPage"
							pagination
							:loading="isLoading"
						>
							<template #id="{item}">
								<td align="center">{{cards.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #allowing_access="{item}">
								<td>
									<p>{{item.allowing_access.split(',').length + ' Room(s)'}}</p>
								</td>
							</template>
							<template #name="{item}">
								<td>{{item.name}}<br>Role: <strong>{{item.role}}</strong></td>
							</template>
							<template #created_at="{item}">
								<td align="center">
									<p>{{item.created_at | formatDate}} {{item.created_at | formatTime}}</p>
								</td>
							</template>
							<template #action="{item}">
            		<td align="center">
              		<CButton color="primary" variant="outline" square size="sm" @click="editCard(item.id)">Edit</CButton> &nbsp; 
									<CButton color="danger" variant="outline" square size="sm" @click="removeCard(item.id)">Delete</CButton>
            		</td>
          		</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'TableMasterCard',
	components: { TheTableHeader },
	data () {
		return {
			role: localStorage.getItem('role'),
			cards: [],
			fields: [
				{ key: 'id', label: 'No' }, 
				'card_number', 
				{ key: 'name', label: 'Card Holder' }, 
				{ key: 'company_name', label: 'Company' }, 
				{ key: 'site_name', label: 'Data Center' }, 
				{ key: 'allowing_access', label: 'Allowed Rooms' }, 
				'created_at', 
				'action'
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 1,
			isLoading: true,
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Kartu Akses',
        }
      ],
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goCreate() {
      this.$router.push({ path: 'cards/form/0' });
    },
		getBadge(status) {
			return status === 1 ? 'success' : 'danger'
		},
		getCards() {
			let self = this;
			return http.get('/card')
			.then(function (response) {
				self.cards = response.data.data;
				self.isLoading = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		editCard(id) {
      this.$router.push({ path: 'cards/form/' + id });
    },
		removeCard(id) {
			let self = this;
			if (confirm('Yakin Ingin Menghapus ?')) {
    		return http.delete('/card/' + id)
					.then(function (response) {
						self.getCards();
						self.toast('Berhasil Menghapus Kartu Akses Dari Daftar.', 'info');
						console.log(response);
					}).catch(function (error) {
						console.log(error);
					});
  		}
		}
	},
	mounted: function(){
		this.getCards();
	}
}

</script>
